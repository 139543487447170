import React, { FC } from 'react';
import { MainFacts } from '../MainFacts/MainFacts';
import './mainchooseus.scss';
// @ts-ignore
import mainChooseUs from '../../../assets/img/vegerables.png'

const MainChooseUsInner: FC = () => {
  return (
    <section className='main__chooseus'>
      <header className="main__chooseus__title">
        Почему <p>выбирают</p> именно <p>нас</p>!
      </header>
      {/* <MainFacts/> */}
      <article className="main__chooseus__info">
        <img className='main__chooseus__img' src={mainChooseUs} alt="овощи" />
        <ul className="main__chooseus__info__text">
          <li>
            <b>Наша цель</b> – предоставить нашим клиентам по <strong>доступной цене</strong> качественный поликарбонат. 
          </li>
          <li>
            Мы гарантируем <strong>высокое качество продукции</strong>, что подтверждено <b>техническими свидетельствами</b> , выданными Министерством архитектуры и строительства Республики Беларусь. 
          </li>
          <li>
            Мы предлагаем широкий выбор различных видов поликарбоната от ведущих производителей Республики Беларусь и Российской Федерации. 
          </li>
          <li>
            Мы понимаем, что у каждого клиента свои требования и потребности, поэтому мы предлагаем <strong>поликарбонат разной толщины и плотности</strong> . У нас вы можете купить поликарбонат для теплиц, навесов, крыши, перегородок, ограждений, и других конструкций.    
          </li>
          <li>
            Наши цены на поликарбонат очень конкурентоспособны, а также мы предоставляем скидки на большие объемы заказов. Мы также предлагаем <strong>помощь в организации доставки</strong> по городу и регионам.
          </li>
          <li>
            Чтобы <strong>купить поликарбонат</strong> в нашем магазине, вам нужно выбрать нужный вам вид поликарбоната, указать количество и оформить заказ. Наши менеджеры свяжутся с вами для уточнения деталей заказа и доставки.
          </li>
          <li>
            Если у вас есть какие-либо вопросы, наши специалисты всегда готовы помочь и проконсультировать вас.
          </li>
          <li>
            <b>Таким образом</b>,  если вы ищете надежного поставщика поликарбоната с доступными ценами и быстрой доставкой, обратитесь к нам. <strong>Мы гарантируем высокое качество продукции и профессиональный сервис.</strong> 
          </li>
        </ul>
      </article>
    </section>
  )
}

export const MainChooseUs = React.memo(MainChooseUsInner);