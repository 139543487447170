import React, { FC } from 'react';
import './mainabout.scss';
// @ts-ignore
import mainAbout from '../../../assets/img/main_about-rem-bg.png'

const MainAboutInner: FC = () => {
  return (
    <section className='main__about'>
      <div className="main__about__image">
        <img src={mainAbout} alt="man" loading="lazy"/>
      </div>
      <div className="main__about__info">
        <h2 className="main__about__info__title">
          О поликарбонате
        </h2>
        <div className="main__about__info__text">
          <p>
            Теплицы играют важную роль в создании условий для успешного выращивания растений. Одним из наиболее популярных материалов, используемых для строительства теплиц, является поликарбонат. Этот материал имеет ряд преимуществ, которые делают его идеальным для строительства теплиц.
         </p>
         <p>
          Во-первых, поликарбонат является очень легким материалом. Это означает, что теплицу можно быстро и легко установить.

         </p>
          <p>
          Во-вторых, поликарбонат является очень прочным материалом, что увеличивает срок службы теплицы. Также он является устойчивым к ультрафиолетовым лучам, что означает, что солнечные лучи не будут повреждать структуру полакароната.

          </p>
          <p>
          В-третьих, поликарбонат – экологически чистый материал. Он не содержит химических веществ, что делает его безопасным для выращивания растений.

          </p>
          <p>
            В-четвертых, поликарбонат – дешёвый материал, что делает его доступным для потребителя.

          </p>
          <p>
          В-пятых, поликарбонат – энергосберегающий материал. Он способствует поддержанию условной температуры, уменьшая, таким образом, расходы на энергоснабжение.

          </p>
          <p>
          Вывод:
          Поликарбонат – экономичный, прочный, удобный, экологичный и энергосберегающий материал, что делает его идеальным для строительства теплиц.

          </p>

        </div>
      </div>
    </section>
  )
}

export const MainAbout = React.memo(MainAboutInner);