import React from 'react'
import './loaderv2.scss';

function LoaderInner() {
  return (
    <div className='loaderv2'>
     
    </div>
  )
};

export const Loader_v2 = React.memo(LoaderInner)